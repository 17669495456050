<template>
  <div class="user">
    <top />
    <div class="container margin">
      <side-nav>
        <div class="user__top flex flex__space-between">
          <a-breadcrumb separator=">">
            <a-breadcrumb-item>个人中心</a-breadcrumb-item>
            <a-breadcrumb-item>我的证书</a-breadcrumb-item>
            <a-breadcrumb-item>查看证书</a-breadcrumb-item>
          </a-breadcrumb>
          <div class="user__top-back" @click="back">
            <a-icon type="left" />返回
          </div>
        </div>
        <div class="user__btn">
          <a-button type="primary" @click="change" :loading="loading">重新生成证书</a-button>
        </div>
        <div class="user__content">
          <div class="user__content-item flex flex__space-between">
            <div class="user__text">学习任务:{{taskName}}</div>
            <div class="user__title">证书详情</div>
          </div>
          <!-- imageTemplate -->
          <div class="user__content-item user__content-item_mt">
            <img :src="getImageView(certificatePath)" alt class="user__img" />
            <!-- <img src="@/assets/images/user/name.png" alt class="user__img-name" /> -->
          </div>
        </div>
      </side-nav>
    </div>
  </div>
</template>
 
<script>
import { updateCertificateImg } from "@/api/user.js";
import sideNav from "@/components/side-nav";
import top from "../../components/top.vue";
import { getFileAccessHttpUrl } from "@/utils/tools";
// import { USER_NAME } from "@/store/mutation-types.js";
import Vue from "vue";
export default {
  components: { sideNav, top },
  data() {
    return {
      certificatePath: "",
      id: null,
      taskName: null,
      loading: false,
      userName: Vue.ls.get("USER_NAME")
    };
  },
  created() {
    if (this.$route.query.id) {
      this.certificatePath = this.$route.query.certificatePath;
      this.id = this.$route.query.id;
      this.taskName = this.$route.query.taskName;
    }
  },
  computed: {
    getImageView() {
      return avatar => {
        return getFileAccessHttpUrl(avatar);
      };
    }
  },
  methods: {
    change() {
      this.loading = true
      updateCertificateImg({
        id: this.id
      }).then(res => {
        if(res.success) {
          this.certificatePath = res.result
          this.$message.success('生成成功!');
        } else {
          this.$message.warning(res.message);
        }
      }).finally(() => {
        this.loading = false
      });
    },
    back() {
      this.$router.back();
    }
  }
};
</script>
 
<style lang = "scss" scoped>
.user {
  &__top {
    padding: 0 24px 32px;
    border-bottom: 1px solid #e4e4e4;
    &-back {
      cursor: pointer;
      transition: all 0.4s;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      &:hover {
        color: #376cde;
      }
    }
  }
  &__btn {
    margin-top: 10px;
  }
  &__content {
    padding-top: 68px;
    &-item {
      width: 636px;
      margin: 0 auto;
      &_mt {
        margin-top: 38px;
        position: relative;
      }
    }
  }
  &__img {
    width: 100%;
    &-name {
      position: absolute;
      width: 94px;
      height: 31px;
      left: 50%;
      top: 213px;
      transform: translateX(-50%);
    }
  }
  &__text {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
  }
  &__title {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
  }
}
</style>